export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Zurück zum Menü',
      blog: 'Blog',
      exploreButton: 'Explorieren',
      language: 'Sprache',
      loadMore: 'Mehr Erfahren',
      moreInfo: 'Mehr Informationen',
      opensInNewWindow: 'Wird in einem neuen Fenster geöffnet',
      pageTypes: {
        article: 'Artikel',
        category: 'Kategorie',
        event: 'Veranstaltung',
        page: 'Seite',
        place: 'Ort',
        region: 'Region',
      },
      search: 'Suche',
      selectLanguage: 'Sprache auswählen',
      homepageBreadcrumb: 'Startseite',
      translationVoting: 'Wie gefällt dir diese Übersetzung?',
      translationVotingSuccess: 'Danke für Deine Rückmeldung!',
    },
  },
};
