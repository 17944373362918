export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Takaisin päävalikkoon',
      blog: 'Blog',
      exploreButton: 'Tutustu',
      homepageBreadcrumb: 'Kotisivu',
      language: 'Kieli',
      loadMore: 'Näytä lisää',
      moreInfo: 'Lisää tietoja',
      opensInNewWindow: 'Opens in a new window',
      pageTypes: {
        article: 'Article',
        category: 'Category',
        event: 'Event',
        page: 'Page',
        place: 'Place',
        region: 'Region',
      },
      selectLanguage: 'Select language',
      translationVoting: 'Mitä pidät tästä käännöksestä?',
      translationVotingSuccess: 'Kiitos palautteestasi!',
    },
  },
};
