import { LocaleMessage } from 'vue-i18n';
import { GridWidget, Region } from '~/app/core/apiClient/api';
import { ImageTileInterface } from '~/components/molecules/imageTile/ImageTile';
import { ImageFilterMixinInterface } from '~/mixins';
import { CztImage } from '~/utils/atoms/image';
import { ThemeColors, ThemeRatios } from '~/utils/theme';
import { CztWidgets } from '~/utils/views/widgets';

import GridListStyleEnum = GridWidget.WidgetStyleEnum;
import { PageTag } from '~/utils/pageTag/pageTag';

export { GridListStyleEnum };

export interface TaxonomyList {
  taxonomy: string;
  tags: PageTag[];
}

export type GridItem = ImageTileInterface &
  ImageFilterMixinInterface & {
    description: string;
    date?: string;
    endDate?: string;
    perex?: string;
    link: string;
    linkText: string;
    title: string;
    location?: Region;
    flag?: string;
    showFlag?: boolean;
    forcedLocale?: string;
    subtitle?: string;
  };

export interface GridListInterface {
  anchorId?: string;
  anchorName?: string;
  buttonText?: string;
  className: CztWidgets;
  guid: string;
  image?: CztImage;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  items: GridItem[];
  imageAspectRatio?: ThemeRatios;
  link?: string;
  maxItems?: number;
  nextPageCount?: number;
  pageSize?: number;
  overlayColor?: ThemeColors;
  showFlags: boolean;
  taxonomyFilter?: PageTag[];
  taxonomies?: string[];
  taxonomyLists?: TaxonomyList[];
  type: GridListStyleEnum;
  title: string | LocaleMessage;
}
