export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: '返回菜单',
      blog: '博客',
      exploreButton: '探索',
      language: '语言',
      loadMore: '更多',
      moreInfo: '更多信息',
      opensInNewWindow: '在新视窗开启',
      pageTypes: {
        article: '文章',
        category: '类别',
        event: '事件',
        page: '页',
        place: '地方',
        region: '地区',
      },
      selectLanguage: '选择语言',
      homepageBreadcrumb: '主页',
      translationVoting: '你喜欢这个翻译吗？',
      translationVotingSuccess: '谢谢你的反馈意见!',
    },
  },
};
