import Vue from 'vue';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import appEnv from '../../appEnv';

import AbstractModule from './AbstractModule';

export interface Newsletter {
  name: string;
  guid: string;
  title: string;
}

type NewsletterLocalisationsCommit = boolean;

const defaultCodename = 'default';

export function getNewsletterKey({
  codename,
  path,
}: {
  codename?: string;
  path?: string;
}): string {
  return codename && path ? `${codename}|${path}` : defaultCodename;
}
@Module({
  name: 'NewsletterModule',
  stateFactory: true,
  namespaced: true,
})
export default class NewsletterModule extends AbstractModule {
  public loading: boolean = false;
  public newsletterPromiseCache: {
    [key: string]: Promise<NewsletterLocalisationsCommit>;
  } = {};
  public subscribing: boolean = false;

  public newsletterCache: { [key: string]: Newsletter[] } = {};

  @Action({ rawError: true })
  public getNewsletters({
    codename,
    path,
  }: {
    codename?: string;
    path?: string;
  }): Promise<NewsletterLocalisationsCommit> {
    const key = getNewsletterKey({ codename, path });
    if (this.newsletterPromiseCache.hasOwnProperty(key)) {
      return this.newsletterPromiseCache[key];
    }

    this.setLoading(true);

    const promise: Promise<NewsletterLocalisationsCommit> = this.$api
      .newsletters()
      .newsletterGetNewsletters(codename, path)
      .then((response) => {
        const newsletters: Newsletter[] = [];
        response.forEach((newsletter) => {
          if (
            newsletter.newsletterDisplayName &&
            newsletter.newsletterGUID &&
            newsletter.newsletterName
          ) {
            newsletters.push({
              title: newsletter.newsletterDisplayName,
              guid: newsletter.newsletterGUID,
              name: newsletter.newsletterName,
            });
          }
        });
        this.setNewsletters({ key, newsletters });
        return newsletters.length > 0;
      })
      .finally(() => {
        this.setLoading(false);
      });

    this.setPromise({ key, promise });
    return promise;
  }

  @Action({ rawError: true })
  public subscribe({
    email,
    newsletters,
    reCaptchaToken,
  }: {
    email: string;
    newsletters: string[];
    reCaptchaToken: string;
  }): Promise<boolean> {
    this.setSubscribing(true);
    return this.$api
      .newsletters()
      .newsletterSubscribe(
        {
          email,
          newsletterNames: newsletters,
          reCaptchaToken,
        },
        appEnv.NEWSLETTER_API_KEY
      )
      .finally(() => {
        this.setSubscribing(false);
      });
  }

  @Mutation
  protected setNewsletters({
    key,
    newsletters,
  }: {
    key: string;
    newsletters: Newsletter[];
  }) {
    this.newsletterCache[key] = newsletters;
    Vue.set(this.newsletterCache, key, newsletters);
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setPromise({
    key,
    promise,
  }: {
    key: string;
    promise: Promise<NewsletterLocalisationsCommit>;
  }) {
    this.newsletterPromiseCache[key] = promise;
    Vue.set(this.newsletterPromiseCache, key, promise);
  }

  @Mutation
  protected setSubscribing(state: boolean) {
    this.subscribing = state;
  }
}
