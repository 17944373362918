export default {
  app: {
    newsletter: {
      bottomText:
        'Registrandoti alla newsletter accetti la nostra <a href="~/Privacy-Policy" target="_blank">politica di protezione dei dati personali</a>',
      button: 'Iscriviti',
      ctaDialogParagraph1: 'Vuoi conoscere i prossimi eventi a Praga?',
      ctaDialogParagraph2:
        'Non sai quale castello visitare nel tuo tour in Repubblica Ceca?',
      ctaDialogParagraph3:
        'Iscriviti alla nostra newsletter e riceverai tanti suggerimenti utili per il tuo prossimo viaggio!',
      ctaDialogTitle: 'TOC TOC!',
      ctaDialogBtn: 'CLICCA QUI',
      dialog: {
        headline: 'Seleziona il paese/regione della tua newsletter',
        notice:
          'Il tuo indirizzo di posta elettronica sopraindicato sarà utilizzato solo per l’invio delle nostre newsletter e non sarà trasmesso a nessun altro soggetto. Il tuo indirizzo di posta elettronica sarà registrato a tale scopo fino a quando deciderai di cancellarti volontariamente dalle newsletter. È possibile cancellarsi in qualsiasi momento facendo clic sul link nel messaggio tramite il quale la newsletter è stata ricevuta. Il tuo indirizzo di posta elettronica potrà essere elaborato da una società informatica che garantisce il funzionamento dei nostri siti internet e delle applicazioni web. {0}',
        company: 'Il titolare del trattamento dei dati personali è l’{0}, {1}',
        link: {
          privacy: 'La nostra Privacy Policy si trova qui.',
          controller: 'Agenzia centrale ceca per il turismo – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Il tuo indirizzo email',
      newsletterError:
        'Sembra che non ci siano newsletter a cui iscriverti in questo momento',
      serverError:
        "Si è verificato un errore durante l'esecuzione della richiesta. Per favore riprova",
      success:
        "Per iscriversi con successo alla newsletter, si prega di confermare la registrazione nell'e-mail inviata.",
      title: 'Ricevi la nostra newsletter',
      topText:
        'Registrati per ricevere le ultime informazioni, consigli, futuri eventi e nuove storie da raccontarti',
      validityError: 'Il tuo indirizzo email sembra non essere valido',
    },
  },
};
