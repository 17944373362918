import deepmerge from 'deepmerge';
import Vue from 'vue';
import VueI18n, { I18nOptions, LocaleMessages } from 'vue-i18n';

import { default as i18n_de } from './de-DE';
import { default as i18n_es } from './es-ES';
import { default as i18n_en } from './en-US';
import { default as i18n_fr } from './fr-FR';
import { default as i18n_hu } from './hu-HU';
import { default as i18n_it } from './it-IT';
import { default as i18n_jp } from './ja-JP';
import { default as i18n_pl } from './pl-PL';
import { default as i18n_pt } from './pt-PT';
import { default as i18n_ru } from './ru-RU';
import { default as i18n_cn } from './zh-CN';
import { default as i18n_nl } from './nl-NL';
import { default as i18n_da } from './da-DK';
import { default as i18n_tw } from './zh-TW';
import { default as i18n_sv } from './sv-SE';
import { default as i18n_nb } from './nb-NO';
import { default as i18n_fi } from './fi-FI';
import { default as i18n_sk } from './sk-SK';
import { default as i18n_lt } from './lt-LT';
import { default as i18n_lv } from './lv-LV';
import { default as i18n_ko } from './ko-KR';

import dateFnsEn from 'date-fns/locale/en';
import dateFnsDe from 'date-fns/locale/de';
import dateFnsZh from 'date-fns/locale/zh_cn';
import dateFnsFr from 'date-fns/locale/fr';
import dateFnsHu from 'date-fns/locale/hu';
import dateFnsIt from 'date-fns/locale/it';
import dateFnsJp from 'date-fns/locale/ja';
import dateFnsPl from 'date-fns/locale/pl';
import dateFnsPt from 'date-fns/locale/pt';
import dateFnsRu from 'date-fns/locale/ru';
import dateFnsEs from 'date-fns/locale/es';
import dateFnsNl from 'date-fns/locale/nl';
import dateFnsDa from 'date-fns/locale/da';
import dateFnsTw from 'date-fns/locale/zh_tw';
import dateFnsSv from 'date-fns/locale/sv';
import dateFnsNb from 'date-fns/locale/nb';
import dateFnsFi from 'date-fns/locale/fi';
import dateFnsSk from 'date-fns/locale/sk';
import dateFnsKo from 'date-fns/locale/ko';
import { LanguageLocaleInterface } from '~/utils/localization';

import dateFnsLt from './lt-LT/date-fns';
import dateFnsLv from './lv-LV/date-fns';

Vue.use(VueI18n);

const localizations: LocaleMessages[] = [
  {
    'de-DE': i18n_de,
  },
  {
    'es-ES': i18n_es,
  },
  {
    'en-US': i18n_en,
  },
  {
    'fr-FR': i18n_fr,
  },
  {
    'hu-HU': i18n_hu,
  },
  {
    'it-IT': i18n_it,
  },
  {
    'ja-JP': i18n_jp,
  },
  {
    'pl-PL': i18n_pl,
  },
  {
    'pt-PT': i18n_pt,
  },
  {
    'ru-RU': i18n_ru,
  },
  {
    'zh-CN': i18n_cn,
  },
  {
    'nl-NL': i18n_nl,
  },
  {
    'da-DK': i18n_da,
  },
  {
    'zh-TW': i18n_tw,
  },
  {
    'sv-SE': i18n_sv,
  },
  {
    'nb-NO': i18n_nb,
  },
  {
    'fi-FI': i18n_fi,
  },
  {
    'sk-SK': i18n_sk,
  },
  {
    'lt-LT': i18n_lt,
  },
  {
    'lv-LV': i18n_lv,
  },
  {
    'ko-KR': i18n_ko,
  },
];

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

const defaultLocale = 'en-US';

const supportedLocales: LanguageLocaleInterface[] = [
  {
    fullySupported: true,
    name: '简体中文',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 1,
    public: true,
    slug: 'zh-CN',
  },
  {
    fullySupported: true,
    name: '繁體中文',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 2,
    public: true,
    slug: 'zh-TW',
  },
  {
    fullySupported: true,
    name: 'Dansk',
    newsletter: false,
    newsletterCta: false,
    oldCta: true,
    order: 3,
    public: true,
    slug: 'da-DK',
  },
  {
    fullySupported: true,
    name: 'Deutsch',
    newsletter: false,
    newsletterCta: true,
    newsletterCtaPathChanges: 2,
    oldCta: false,
    order: 4,
    public: true,
    slug: 'de-DE',
  },
  {
    fullySupported: true,
    name: 'English',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 5,
    public: true,
    slug: 'en-US',
  },
  {
    fullySupported: true,
    name: 'Español',
    newsletter: false,
    newsletterCta: false,
    oldCta: true,
    order: 6,
    public: true,
    slug: 'es-ES',
  },
  {
    fullySupported: true,
    name: 'Français',
    newsletter: false,
    newsletterCta: false,
    oldCta: true,
    order: 7,
    public: true,
    slug: 'fr-FR',
  },
  {
    fullySupported: true,
    name: 'Italiano',
    newsletter: false,
    public: true,
    slug: 'it-IT',
    newsletterCta: true,
    oldCta: false,
    order: 8,
  },
  {
    fullySupported: true,
    name: '日本語',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 9,
    public: true,
    slug: 'ja-JP',
  },
  {
    fullySupported: true,
    name: '한국어',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 10,
    public: true,
    slug: 'ko-KR',
  },
  {
    fullySupported: false,
    name: 'Latviešu',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 11,
    public: true,
    slug: 'lv-LV',
  },
  {
    fullySupported: true,
    name: 'Lietuvių',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 12,
    public: true,
    slug: 'lt-LT',
  },
  {
    // TODO: IMPORTANT - Go to Header.tsx and remove logo forced locale exception for Hungarian if setting this to true
    fullySupported: false,
    name: 'Magyar',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 13,
    public: false,
    slug: 'hu-HU',
  },
  {
    fullySupported: true,
    name: 'Nederlands',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 14,
    public: true,
    slug: 'nl-NL',
  },
  {
    fullySupported: true,
    name: 'Norsk',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 15,
    public: true,
    slug: 'nb-NO',
  },
  {
    fullySupported: true,
    name: 'Polski',
    newsletter: false,
    newsletterCta: true,
    immediateNewsletterForm: true,
    oldCta: false,
    order: 16,
    public: true,
    slug: 'pl-PL',
  },
  {
    fullySupported: true,
    name: 'Português',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 17,
    public: true,
    slug: 'pt-PT',
  },
  {
    fullySupported: true,
    name: 'Русский',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 18,
    public: true,
    slug: 'ru-RU',
  },
  {
    fullySupported: false,
    name: 'Slovenčina',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 19,
    public: true,
    slug: 'sk-SK',
  },
  {
    fullySupported: true,
    name: 'Suomi',
    newsletter: false,
    newsletterCta: false,
    oldCta: false,
    order: 20,
    public: true,
    slug: 'fi-FI',
  },
  {
    fullySupported: true,
    name: 'Svenska',
    newsletter: false,
    newsletterCta: false,
    oldCta: true,
    order: 21,
    public: true,
    slug: 'sv-SE',
  },
];

const i18nOptions: I18nOptions = {
  locale: defaultLocale,
  messages: mergedLocalizations,
  pluralizationRules: {
    // TODO: Add pluralization rules for other languages
  },
};

const dateFnsLocalizations: { [key: string]: any } = {
  'de-DE': dateFnsDe,
  'es-ES': dateFnsEs,
  'en-US': dateFnsEn,
  'fr-FR': dateFnsFr,
  'hu-HU': dateFnsHu,
  'it-IT': dateFnsIt,
  'ja-JP': dateFnsJp,
  'pl-PL': dateFnsPl,
  'pt-PT': dateFnsPt,
  'ru-RU': dateFnsRu,
  'zh-CN': dateFnsZh,
  'nl-NL': dateFnsNl,
  'da-DK': dateFnsDa,
  'zh-TW': dateFnsTw,
  'sv-SE': dateFnsSv,
  'nb-NO': dateFnsNb,
  'fi-FI': dateFnsFi,
  'sk-SK': dateFnsSk,
  'lt-LT': dateFnsLt,
  'lv-LV': dateFnsLv,
  'ko-KR': dateFnsKo,
};

const newsletterLocales = supportedLocales
  .filter((locale) => locale.newsletter)
  .map((locale) => locale.slug);

export function isFullySupportedLocale(locale: string): boolean {
  const theLocale = supportedLocales.find(
    (supportedLocale) => supportedLocale.slug === locale
  );
  if (theLocale) {
    return theLocale.fullySupported;
  }
  return false;
}

export function isNewsletterSupportedLocale(locale: string): boolean {
  return newsletterLocales.includes(locale);
}

export function createI18n() {
  return new VueI18n(i18nOptions);
}

interface RouteLocalization {
  routes: {
    [key: string]: {
      name: string;
      path: string;
    };
  };
}

export {
  defaultLocale,
  RouteLocalization,
  supportedLocales,
  dateFnsLocalizations,
};
