import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import Quiz from '../organisms/quiz/Quiz';
import { QuizProps } from '../organisms/quiz/types';
import { CztWidgets } from '~/utils/views/widgets';

export interface QuizWrapperProps {
  imageSource: string;
}

/**
 * This component should never be used outside of the HTML Renderer.
 */
@Component
export default class QuizWrapper extends VueComponent<QuizWrapperProps> {
  @Prop({ required: true })
  public quiz!: string;

  protected quizData: QuizProps | null = null;

  public created() {
    try {
      this.quizData = JSON.parse(this.quiz);
    } catch {
      // noop
    }
  }

  public render() {
    if (!this.quizData) {
      return;
    }
    return (
      <Quiz
        class='px-0'
        className={CztWidgets.QUIZ}
        content={this.quizData.content}
        questions={this.quizData.questions}
        title={this.quizData.title}
      />
    );
  }
}
