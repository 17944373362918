import { CztWidgets } from '~/utils/views/widgets';
import { QuizProps } from './types';

export default function(data: any): data is QuizProps {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.QUIZ &&
    typeof data.content !== 'undefined'
  );
}
