/**
 * Interface that holds separate languages defined in Accept-Language header
 */
interface AcceptLanguage {
  quality: number;
  index: number;
  language: string;
}

/**
 * Locales that are not exact match, but partially match the Accept-Language header defined languages
 */
interface CandidateLocale extends AcceptLanguage {
  matchedParts: number;
}

/**
 * Helper interface to map supported languages to normalized value
 */
interface NormalizedSupportedLanguage {
  original: string;
  normalized: string;
}

/**
 * Regex to parse Accept-Language header by language and quality
 */
const acceptLanguageRegex = /((,?([A-Za-z0-9]([-_][A-Za-z0-9])*)+)*)(;q=([0-9](.[0-9]+)*))?,?/g;

/**
 * Compares the input language vs the supported one and returns the number of matched parts
 *
 * e.g. en-US vs en returns 1
 * en-US-private1 vs en-US returns 2
 *
 * en vs en-US returns 1
 * en vs en-GB returns 1
 * en-US vs en-GB returns 1
 *
 * @param language
 * @param supportedLanguage
 */
export function getPartialMatch(
  language: string,
  supportedLanguage: string
): number {
  const languageParts = language.split('-');
  const supportedLanguageParts = supportedLanguage.split('-');
  let matches = 0;
  for (let i = 0; i < languageParts.length; i++) {
    if (i >= supportedLanguageParts.length) {
      break;
    }

    if (
      supportedLanguageParts[i] &&
      languageParts[i] === supportedLanguageParts[i]
    ) {
      matches++;
    }
  }

  return matches;
}

/**
 * Normalizes language strings so we compare lowercased strings with same separator
 * @param value
 */
function normalizeLanguageString(value: string): string {
  return value
    .trim()
    .toLowerCase()
    .replace('_', '-');
}

/**
 * Finds the best language to fit the Accept-Language header
 *
 * @param supportedLanguages
 * @param acceptLanguageHeader
 * @param defaultLanguage
 */
export default function(
  supportedLanguages: string[],
  acceptLanguageHeader: string,
  defaultLanguage: string
): string {
  // Parsed from Accept-Language header
  const userLanguages: AcceptLanguage[] = [];
  // These are partially matched languages
  const candidateLocales: CandidateLocale[] = [];

  // Create normalized and original value of supported languages
  // Normalized value is used to match against user language, while original value is returned from the function
  const normalizedSupportedLanguages: NormalizedSupportedLanguage[] = supportedLanguages.map(
    (original) => ({
      original,
      normalized: normalizeLanguageString(original),
    })
  );

  // Create user languages from Accept-Language header
  for (const languageConfig of acceptLanguageHeader.matchAll(
    acceptLanguageRegex
  )) {
    // There can be multiple languages defined for the same quality
    const languages = languageConfig[1];
    const quality = parseFloat(languageConfig[6]) || 1;
    if (languages.trim() !== '' && quality) {
      // We will also hold the index so we prefer language version defined earlier rather than later
      let index = 0;
      languages.split(',').forEach((language) => {
        const normalizedLanguage = normalizeLanguageString(language);
        if (normalizedLanguage !== '') {
          userLanguages.push({
            language: normalizedLanguage,
            index: index++,
            quality,
          });
        }
      });
    }
  }

  // Make sure we have something to work with
  if (userLanguages.length < 1 || supportedLanguages.length < 1) {
    return defaultLanguage;
  }

  // Sort user provided languages by quality, then index in which they are defined
  userLanguages.sort((a, b) => {
    const qDiff = b.quality - a.quality;

    if (qDiff === 0) {
      return a.index - b.index;
    }

    return qDiff;
  });

  let bestCandidateQuality = 0;

  for (const lang of userLanguages) {
    if (bestCandidateQuality > lang.quality) {
      // Do not continue, if we have a candidate with higher quality, userLanguages are sorted
      // by quality first, so it is safe to break here and ignore the rest
      break;
    }

    for (const supportedLang of normalizedSupportedLanguages) {
      // If we get an exact match, return the supported lang immediately
      if (lang.language === supportedLang.normalized) {
        return supportedLang.original;
      }

      // Check if we have a partial match, if so, use the language as a candidate
      const matchedParts = getPartialMatch(
        lang.language,
        supportedLang.normalized
      );

      if (matchedParts > 0) {
        if (lang.quality > bestCandidateQuality) {
          // Set the highest quality we can have
          bestCandidateQuality = lang.quality;
        }
        candidateLocales.push({
          index: lang.index,
          language: supportedLang.original,
          matchedParts,
          quality: lang.quality,
        });
      }
    }
  }

  if (candidateLocales.length > 0) {
    // Sort candidates by quality, number of matched parts and then the index in which they are defined
    candidateLocales.sort((a, b) => {
      const qDiff = b.quality - a.quality;

      if (qDiff === 0) {
        const partsDiff = a.matchedParts - b.matchedParts;
        if (partsDiff === 0) {
          return a.index - b.index;
        }

        return partsDiff;
      }

      return qDiff;
    });

    // After sorting the candidates, return the first one
    return candidateLocales[0].language;
  }

  // And eventually fallback to default language
  return defaultLanguage;
}
