export enum PinCategory {
  CULTURE = '4f51c90c-0b50-4084-9fdb-e06d4da76d91',
  LANDMARKS = '1c29d3b3-6eea-4173-a64d-1bee9ea2aaca',
  EXPERIENCES = '93c27220-7af5-4ca4-ad2b-dd985142097b',
  RESTAURANTS = 'a0cce1e6-900f-4adb-8149-b6d536ff4d4d',
  SUMMER_SPORTS = '03406599-5ceb-49a7-94b3-c05c157f832e',
  WINTER_SPORTS = '5fbb1a2d-8e9d-4bf7-ac7d-1b46f5318672',
  NATURE = '1489c074-c9ed-4c0f-a165-7b80e3aa9bc1',
  SPA = 'db20f94b-f1b0-4773-a3cf-85e04ea02fa3',
  LIFESTYLE = 'ed8b3112-f8c6-47e4-a66b-ad5425d2f2e5',
  ACCOMMODATION = 'c15be096-911c-460a-829b-2081e4b92c13',
  EVENT = 'event',
  DEFAULT = 'default',
}
