import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline } from '~/components/atoms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Align } from '~/components/atoms/headline/Headline';
import { CztWidgets } from '~/utils/views/widgets';

import style from './Quiz.scss';
import { QuizProps, QuizQuestionProps } from './types';
import QuizQuestion from './QuizQuestion';

const rootClass: string = 'czt-quiz';

@Component({
  style,
})
export default class Quiz extends VueComponent<QuizProps> implements QuizProps {
  @Prop()
  public anchorId?: string;

  @Prop()
  public anchorName?: string;

  @Prop({ required: true, type: String })
  public content!: string;

  @Prop({ required: true })
  public questions!: QuizQuestionProps[];

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true, type: String })
  public title!: string;

  public className = CztWidgets.QUIZ;

  protected answers: boolean[] = [];

  public render() {
    const rootClasses: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }

    return (
      <v-container id={this.anchorId} class={rootClasses.join(' ')}>
        <v-row>
          <v-col cols={12} class={`${rootClass}__headline`}>
            <Headline level={2} underscore align={Align.LEFT}>
              {this.title}
            </Headline>
          </v-col>
          <v-col cols={12} class='pb-0 pt-md-0'>
            <HtmlRenderer content={this.content} />
          </v-col>
          <v-col cols={12}>
            {this.questions.map((question, index) => (
              <QuizQuestion
                key={`${question.correctAnswerId}-${index}`}
                answers={question.answers}
                correctAnswerId={question.correctAnswerId}
                question={question.question}
                imageUrl={question.imageUrl}
                imageSource={question.imageSource}
                onAnswer={(correct) => {
                  this.answers.push(correct);
                }}
              />
            ))}
            <v-fade-transition>
              {this.answers.length === this.questions.length && (
                <div class='czt-description py-2'>
                  {this.$t('app.quiz.nrOfCorrectAnswers', {
                    state: [
                      this.answers.filter(Boolean).length,
                      this.questions.length,
                    ].join('/'),
                  })}
                </div>
              )}
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
    );
  }
}
