import { VueComponent } from '~/utils/vue-component';
import { BreadcrumbInterface } from '~/components/molecules/breadcrumbItem/BreadcrumbItem';
import { Component, Prop } from 'vue-property-decorator';
import { BreadcrumbItem } from '~/components/molecules';
import { Icons } from '~/components/atoms';

import style from './Breadcrumbs.scss';

interface BreadcrumbsInterface {
  breadcrumbs: BreadcrumbInterface[];
  light?: boolean;
}

const rootClass = 'czt-breadcrumbs';

@Component({ style })
export default class Breadcrumbs extends VueComponent<BreadcrumbsInterface>
  implements BreadcrumbsInterface {
  @Prop({ required: true })
  public breadcrumbs!: BreadcrumbInterface[];

  @Prop({ default: false })
  public light!: boolean;

  protected get breadcrumbItems(): BreadcrumbInterface[] {
    return this.breadcrumbs.map((item) => {
      if (item.url && item.url.toLowerCase().indexOf('campaigns') > -1) {
        item.url = '';
      }
      return item;
    });
  }

  protected get classes() {
    const clss = [rootClass];
    if (this.light) {
      clss.push(`${rootClass}--light`);
    }
    return clss;
  }

  public render() {
    if (this.breadcrumbs.length < 1) {
      return;
    }

    return (
      <div class={this.classes.join(' ')}>
        {this.breadcrumbItems.map((breadcrumb, index) => {
          return (
            <div class={`${rootClass}__item`}>
              {!!index && <Icons.common.ArrowRight />}
              <BreadcrumbItem
                title={breadcrumb.title}
                url={breadcrumb.url}
                forcedLocale={breadcrumb.forcedLocale}
                light={this.light}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
