import CookieSettingsModule from './CookieSettingsModule';
import DomainModule from './DomainModule';
import FacebookSdkModule from './FacebookSdkModule';
import ImageObserverModule from './ImageObserverModule';
import LanguageModule from './LanguageModule';
import MapPinsModule from './MapPinsModule';
import NavigationModule from './NavigationModule';
import NewsletterModule from './NewsletterModule';
import RecaptchaModule from './RecaptchaModule';
import RouterModule from './RouterModule';
import SafeTravelsModule from './SafeTravelsModule';
import SearchModule from './SearchModule';
import TranslationModule from './TranslationModule';
import UserModule from './UserModule';
import VenueModule from './VenueModule';
import VenueFinderModule from './VenueFinderModule';
import VisitMetricsModule from './VisitMetricsModule';
import YoutubeVideosModule from './YoutubeVideosModule';
import ServerTimingModule from './LoggerModule';

const modules: { [s: string]: any } = {
  CookieSettingsModule,
  DomainModule,
  FacebookSdkModule,
  ImageObserverModule,
  LanguageModule,
  MapPinsModule,
  NavigationModule,
  NewsletterModule,
  ServerTimingModule,
  RecaptchaModule,
  RouterModule,
  SafeTravelsModule,
  SearchModule,
  TranslationModule,
  UserModule,
  VenueModule,
  VenueFinderModule,
  VisitMetricsModule,
  YoutubeVideosModule,
};

export { modules };
