import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';

import { defaultLocale, supportedLocales } from '~/app/localization';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import routes from './routes';
import redirectRoutes from './routes/redirects';
import appEnv from '~/app/core/appEnv';
import RouterModule from '../store/modules/RouterModule';
import Homepage from '~/views/Homepage';

declare var originalHash: string;

Vue.use(Router);

function scrollToElement(hash: string) {
  if (document) {
    const anchor = document.querySelector(hash);
    if (anchor) {
      const scrollTo = Math.round(
        anchor.getBoundingClientRect().top + window.scrollY
      );
      window.scrollTo({ top: scrollTo, behavior: 'smooth' });
      return true;
    }
    return false;
  }
  return false;
}

export function getLocaleFromRoute(route: Route): string {
  const { matched } = route;

  for (const routeRecord of matched) {
    if (routeRecord.meta.locale) {
      return routeRecord.meta.locale;
    }
  }

  return defaultLocale;
}

export function getLocaleFromRouter(router: Router): string {
  return getLocaleFromRoute(router.currentRoute);
}

export function getPathWithoutLocaleFromRoute(route: Route): string {
  const locale = getLocaleFromRoute(route);
  const path = route.path;

  const regex = new RegExp(`^/${locale}`, 'gi');
  return path.replace(regex, '');
}

export function getPathWithoutLocale(router: Router): string {
  const route = router.currentRoute;
  return getPathWithoutLocaleFromRoute(route);
}

export function getPathWithLocale(
  path: string,
  router?: Router,
  forcedLocale?: string
): string {
  return `/${forcedLocale ||
    (router
      ? getLocaleFromRouter(router)
      : defaultLocale)}${path}`.toLowerCase();
}

export function getSimpleUrlFromPath(path: string): string {
  return `${appEnv.PUBLIC_WEB_URL}${path}`;
}

export function getUrlFromPath(
  path: string,
  router: Router,
  keepQuery: boolean = false
): string {
  let query: string | null = null;
  if (keepQuery) {
    const splitUrl = router.currentRoute.fullPath.split('?');
    if (splitUrl.length >= 2) {
      splitUrl.shift();
      query = splitUrl.join('?');
    }
  }
  return `${appEnv.PUBLIC_WEB_URL}${getPathWithLocale(path, router)}${
    !!query ? `?${query}` : ''
  }`;
}

// Generate language routes
const languageRoutes = supportedLocales.map(
  (locale): RouteConfig => {
    const languageConfig: RouteConfig = {
      path: `/${locale.slug.toLowerCase()}`,
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        locale: locale.slug,
      },
      alias: `/${locale.slug}`,
    };

    languageConfig.children = [];

    for (const route of routes) {
      languageConfig.children.push({
        ...route,
        name: route.name ? `${route.name} ${locale.slug}` : undefined,
      });
    }

    return languageConfig;
  }
);

export default function(store: Store<any>) {
  return new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: '/',
    routes: [
      ...redirectRoutes,
      ...languageRoutes,
      {
        path: '/',
        component: Homepage,
        name: 'Homepage',
        meta: {
          locale: defaultLocale,
        },
      },
      {
        path: '*',
        redirect: () => {
          const path = `/${
            getModule(LanguageModule, store).initialLanguage
          }`.toLowerCase();
          // We need a callback here as the initial language is replaced only after the app is created
          return {
            path,
            query: {
              temporaryRedirect: appEnv.PUBLIC_WEB_URL + path,
            },
          };
        },
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      const hash = to.hash || originalHash;
      const routerModule = getModule(RouterModule, store);
      if (originalHash) {
        originalHash = '';
      }
      if (hash) {
        const position = {
          behavior: 'smooth',
          selector: hash,
        };
        if (routerModule.loading) {
          return new Promise((resolve, reject) => {
            let finished = false;
            routerModule.addMiddleware({
              id: 'scrollBehavior',
              middleware: () => {
                routerModule.removeMiddleware('scrollBehavior');
                finished = true;
                scrollToElement(hash) ? resolve() : resolve(position);
              },
            });
            setTimeout(() => {
              if (!finished) {
                resolve(position);
              }
            }, 5000);
          });
        }

        return scrollToElement(hash) ? undefined : position;
      }

      if (routerModule.loading) {
        return new Promise((resolve, reject) => {
          const position = savedPosition
            ? savedPosition
            : { x: 0, y: from.meta.scrollY || 0 };
          let finished = false;
          routerModule.addMiddleware({
            id: 'scrollBehavior',
            middleware: () => {
              routerModule.removeMiddleware('scrollBehavior');
              finished = true;
              resolve(position);
            },
          });
          setTimeout(() => {
            if (!finished) {
              resolve(position);
            }
          }, 5000);
        });
      }
      return savedPosition
        ? savedPosition
        : { x: 0, y: from.meta.scrollY || 0 };
    },
  });
}
