export enum MapVariant {
  SIMPLE = 'simple',
  MOVABLE = 'movable',
  FULL = 'full',
}

export enum MapStyle {
  BASIC = 'basic',
  OUTDOOR = 'outdoor',
  AERIAL = 'aerial',
  WINTER = 'winter',
}
