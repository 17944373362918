import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import MapPinsModule from '~/app/core/store/modules/MapPinsModule';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/multiselect/Multiselect';
import { IconText } from '~/components/molecules';
import {
  mdiImageFilterCenterFocus,
  mdiImageSearchOutline,
} from '~/utils/iconPaths';
import { ThemeColors } from '~/utils/theme';
import { VueComponent } from '~/utils/vue-component';
import { getCategoryColor, getCategoryIcon } from './MapCategoryPin';

import style from './MapPinFilter.scss';
import appEnv from '~/app/core/appEnv';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { Colors } from '~/utils/theme/colors';

export const MAP_CATEGORY_DELIMITER = ',';

interface MapPinFilterProps {
  loading: boolean;
  onCategoryChange?: (categories: string[]) => void;
  onLocationChange?: (location: [number, number]) => void;
}

const rootClass = 'czt-map-filter';

@Component({ style })
export default class MapPinFilter extends VueComponent<MapPinFilterProps>
  implements MapPinFilterProps {
  @Prop({ required: true })
  public loading!: boolean;

  protected open: boolean = true;

  protected selectedLocation: [number, number] | null = null;

  protected categories: string[] = [];
  protected location: string = '';

  protected showLocationButton = false;

  protected locationLoading = false;
  protected locationError = '';

  protected searchThrottle?: NodeJS.Timeout;
  protected queryCache: { [key: string]: any } = {};

  protected suggestions: ItemData[] = [];
  protected suggestionsOpen: boolean = false;

  protected get mapPinsModule() {
    return getModule(MapPinsModule, this.$store);
  }

  protected get isMobile(): boolean {
    return getModule(RouterModule, this.$store).isMobile;
  }

  public mounted() {
    if (this.isMobile) {
      this.open = false;
    }
    if (navigator !== undefined && 'geolocation' in navigator) {
      this.showLocationButton = true;
    }
    this.mapPinsModule.getMapCategories(this.$i18n.locale);
  }

  public render() {
    return (
      <div class={rootClass}>
        <v-btn
          color={ThemeColors.PRIMARY}
          dark
          fab
          absolute
          top
          left
          style={{
            left: '24px',
            top: '24px',
          }}
          onClick={() => {
            this.open = !this.open;
          }}
        >
          <v-icon>{mdiImageSearchOutline}</v-icon>
        </v-btn>
        {this.open && (
          <v-sheet
            class={`${rootClass}__fields`}
            color='transparent'
            onClick={(e?: Event) => {
              if (e) {
                e.stopImmediatePropagation();
              }
            }}
          >
            <v-container fluid class='pa-0'>
              <v-row no-gutters>
                <v-col cols={12} class={`${rootClass}__field`}>
                  <form
                    class='d-flex'
                    style={{ position: 'relative' }}
                    onSubmit={(e: Event) => {
                      e.preventDefault();
                      if (this.selectedLocation) {
                        this.changeLocation(this.selectedLocation);
                        this.suggestionsOpen = false;
                      }
                    }}
                  >
                    <InputField
                      label={this.$t('app.maps.location')}
                      v-model={this.location}
                      errorMessage={this.locationError}
                      disabled={this.locationLoading}
                      onFocus={() => {
                        if (!this.suggestionsOpen) {
                          this.suggestionsOpen = true;
                        }
                      }}
                      onBlur={() => {
                        if (this.suggestionsOpen) {
                          setTimeout(() => {
                            this.suggestionsOpen = false;
                          }, 100);
                        }
                      }}
                    />
                    {this.showLocationButton && (
                      <Button
                        style='height: 56px !important; min-width: 0 !important; width: 56px; padding: 0'
                        onClick={this.getCurrentLocation}
                        loading={this.locationLoading}
                      >
                        <v-icon>{mdiImageFilterCenterFocus}</v-icon>
                      </Button>
                    )}
                    {this.suggestionsOpen && (
                      <v-sheet
                        style={{
                          position: 'absolute',
                          top: '56px',
                          left: 0,
                          zIndex: 1,
                          maxHeight: '250px',
                          overflowY: 'auto',
                        }}
                      >
                        <v-list>
                          {this.suggestions.map((suggestion, index) => {
                            return (
                              <v-list-item
                                dense
                                style={{
                                  backgroundColor:
                                    index % 2 ? Colors.TEXT_LIGHT : '#F6F6F6',
                                }}
                                onClick={() => {
                                  this.selectedLocation = (((suggestion.value as unknown) as string).split(
                                    '|'
                                  ) as unknown) as [number, number];
                                  this.changeLocation(
                                    (((suggestion.value as unknown) as string).split(
                                      '|'
                                    ) as unknown) as [number, number]
                                  );
                                  this.location = suggestion.text;
                                }}
                              >
                                {suggestion.text}
                              </v-list-item>
                            );
                          })}
                        </v-list>
                      </v-sheet>
                    )}
                  </form>
                </v-col>
                <v-col
                  cols={12}
                  class={`${rootClass}__field`}
                  id={`${rootClass}_categories`}
                  style='position: relative'
                >
                  <Multiselect
                    attach={`#${rootClass}_categories`}
                    label={this.$t('app.maps.categories')}
                    items={this.mapPinsModule.categoryOptions}
                    disabled={this.loading}
                    loading={this.loading}
                    v-model={this.categories}
                    itemSlot={(data) => {
                      const iconName = getCategoryIcon(
                        data.item.value.toString()
                      );
                      const element = iconName ? (
                        <IconText
                          iconMargin='0.6em'
                          iconSize={0.8}
                          iconColor={getCategoryColor(
                            data.item.value.toString()
                          )}
                          icon={iconName}
                        >
                          {data.item.text}
                        </IconText>
                      ) : (
                        data.item.text
                      );
                      return (
                        <v-list-item-content>
                          <v-list-item-title>{element}</v-list-item-title>
                        </v-list-item-content>
                      );
                    }}
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        )}
      </div>
    );
  }

  protected getCurrentLocation() {
    this.locationError = '';
    this.locationLoading = true;
    this.suggestions = [];
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetch(
          `https://api.mapy.cz/v1/rgeocode/?lon=${position.coords.longitude}&lat=${position.coords.latitude}&lang=en&apikey=${appEnv.MAPS_API_KEY}`,
          {
            mode: 'cors',
          }
        )
          .then((response) => response.json())
          .then((json) => {
            if (json?.items?.length > 0) {
              this.location = `${json.items[0].name}, ${json.items[0].location}`;
              this.selectedLocation = [
                position.coords.latitude,
                position.coords.longitude,
              ];
              this.changeLocation([
                position.coords.latitude,
                position.coords.longitude,
              ]);
            }
            this.locationLoading = false;
          })
          .catch(() => {
            this.location = 'N/A';
            this.selectedLocation = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            this.changeLocation([
              position.coords.latitude,
              position.coords.longitude,
            ]);
            this.locationLoading = false;
          });
      },
      (error) => {
        this.locationLoading = false;
        this.locationError = error.message;
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      }
    );
  }

  @Watch('location')
  protected suggest(value: string) {
    this.locationError = '';
    if (this.searchThrottle) {
      clearTimeout(this.searchThrottle);
    }
    this.searchThrottle = setTimeout(() => {
      if (!value) {
        this.suggestions = [];
        this.selectedLocation = null;
        return;
      }
      if (this.queryCache[value]) {
        this.suggestions = this.queryCache[value];
      }
      fetch(
        `https://api.mapy.cz/v1/suggest?lang=en&limit=10&type=regional.address&locality=cz&apikey=${appEnv.MAPS_API_KEY}&query=${value}`
      )
        .then((response) => response.json())
        .then((jsonData) => {
          // IF THIS EVER BREAKS IT'S MOST LIKELY GOING TO BE HERE
          const items: ItemData[] = jsonData.items.map((item: any) => ({
            text: `${item.name}, ${item.location}`,
            value: [item.position.lat, item.position.lon].join('|'),
          }));
          this.queryCache[value] = items;
          this.suggestions = items;
        })
        .catch(() => {
          this.suggestions = [];
        });
    }, 300);
  }

  @Watch('categories', { deep: true })
  protected changeCategories() {
    this.$emit('categoryChange', this.categories);
  }

  @Emit('locationChange')
  protected changeLocation(location: [number, number]) {
    return location;
  }
}
