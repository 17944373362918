import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getPathWithoutLocale } from '~/app/core/router';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { supportedLocales } from '~/app/localization';

import { VueComponent } from '~/utils/vue-component';
import { LanguageLocaleInterface } from '~/utils/localization';

import style from './MobileNavigation.scss';
import { Link } from '~/components/molecules';
import { Icons, Image } from '~/components/atoms';
import logoWhite from '~/assets/logo_red-white.svg';
import {
  isNavigationGroup,
  isNavigationLink,
  NavigationElement,
  NavigationLink,
} from '~/utils/navigation';

interface MobileNavigationInterface {
  items: NavigationElement[];
}

const rootClass: string = 'czt-mobile-navigation';

@Component({
  style,
})
export default class MobileNavigation extends VueComponent<
  MobileNavigationInterface
> {
  @Prop({ required: true, type: Array })
  public items!: NavigationElement[];

  protected get routerStore(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected currentPath: string = '';

  protected menuVisible: boolean = false;

  private closeMenu(): void {
    this.menuVisible = false;
    this.$emit('closed');
  }

  public render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <span
          style={{ width: '100%', height: '100%' }}
          class={`${rootClass}__button`}
          {...{
            on: {
              click: () => (this.menuVisible = true),
            },
          }}
          role='button'
          aria-label='Menu'
        >
          <Icons.common.Menu size={2.5} />
        </span>
        <v-menu
          content-class={`${rootClass} v-menu__content--fixed`}
          close-on-content-click={false}
          v-model={this.menuVisible}
          min-width='100%'
          eager
        >
          <v-list
            class={`${rootClass}__list overflow-y-auto`}
            min-width='260px'
            max-height='100vh'
          >
            {this.renderMenuTitle()}
            {this.items.map((item, index) =>
              this.renderMenuItems(item, index, 0)
            )}
            {this.renderLanguageSwitch()}
          </v-list>
        </v-menu>
      </div>
    );
  }

  protected renderMenuTitle(): JSX.Element {
    const logoImage: JSX.Element = (
      <Image
        src={logoWhite}
        class={`${rootClass}__logo`}
        alt='#VisitCzechia Logo'
        lazy={false}
        onClick={this.closeMenu}
      />
    );
    return (
      <v-list-item style={{ padding: '0 18px' }}>
        <v-list-item-content style={{ padding: '0' }}>
          <v-layout align-center style={{ height: '100px' }}>
            <Link url='~/' style={{ margin: '0' }}>
              {logoImage}
            </Link>
          </v-layout>
          <a onClick={this.closeMenu} class={`${rootClass}__close`}>
            <Icons.common.Close size={1.9} />
          </a>
        </v-list-item-content>
      </v-list-item>
    );
  }

  protected renderMenuItems(
    item: NavigationElement,
    index: number,
    depth: number
  ): JSX.Element {
    const classNames = [`${rootClass}__item`];
    if (depth > 0) {
      classNames.push(
        `${rootClass}__item--sub`,
        `${rootClass}__item--sub--${depth}`
      );
    }

    if (isNavigationGroup(item)) {
      return (
        <v-list-group
          key={index}
          class={`${rootClass}__group`}
          sub-group={depth > 0}
        >
          <v-list-item-title slot='activator' class={`${rootClass}__title`}>
            {item.title}
          </v-list-item-title>
          {item.items.map((child: NavigationElement, childIndex: number) =>
            this.renderMenuItems(child, childIndex, depth + 1)
          )}
        </v-list-group>
      );
    }

    return (
      <v-list-item key={index} class={classNames}>
        <Link
          url={item.url}
          forcedLocale={item.forcedLocale}
          onClick={this.closeMenu}
        >
          {item.title}
        </Link>
      </v-list-item>
    );
  }

  protected renderLanguageSwitch(): JSX.Element | undefined {
    const languageSwitchItems: NavigationElement[] = supportedLocales
      .filter(
        (item: LanguageLocaleInterface) =>
          item.public &&
          item.slug !== this.$i18n.locale &&
          (!this.routerStore.resource ||
            (this.routerStore.resource &&
              this.routerStore.resource?.allowedCultures.indexOf(item.slug) >
                -1))
      )
      .sort((a, b) => a.order - b.order)
      .map((item: LanguageLocaleInterface) => {
        return {
          title: item.name,
          url: this.getLocaleLink(item.slug).toLowerCase(),
        };
      });
    if (languageSwitchItems.length < 1) {
      return;
    }
    const classNames = [`${rootClass}__item`, `${rootClass}__item--sub`];

    return (
      <v-list-group
        key={`mobileLanguageSwitchMenu-${this.$i18n.locale}`}
        class={`${rootClass}__group`}
      >
        <v-list-item-title slot='activator' class={`${rootClass}__title`}>
          {this.$t('app.common.language')}
        </v-list-item-title>
        {languageSwitchItems
          .filter(isNavigationLink)
          .map((item: NavigationLink, index: number) => (
            <v-list-item key={index} class={classNames}>
              <router-link to={item.url} nativeOnClick={this.closeMenu}>
                {item.title}
              </router-link>
            </v-list-item>
          ))}
      </v-list-group>
    );
  }

  @Watch('$route', { immediate: true, deep: true })
  protected computeCurrentPath(): void {
    this.currentPath = getPathWithoutLocale(this.$router);
  }

  protected getLocaleLink(locale: string): string {
    if (
      this.routerStore.resource &&
      this.routerStore.resource.availableCultures.indexOf(locale) >= 0
    ) {
      return `/${locale}${this.currentPath}`;
    }

    return `/${locale}`;
  }
}
