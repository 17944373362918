export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Voltar ao menu',
      blog: 'Blog',
      exploreButton: 'Explorar',
      language: 'Idioma',
      loadMore: 'Veja mais',
      moreInfo: 'Mais Informação',
      opensInNewWindow: 'Abre em uma nova janela',
      pageTypes: {
        article: 'Artigo',
        category: 'Categoria',
        event: 'Evento',
        page: 'Página',
        place: 'Lugar',
        region: 'Região',
      },
      selectLanguage: 'Selecione o idioma',
      homepageBreadcrumb: 'Home',
      translationVoting: 'O que achou desta tradução?',
      translationVotingSuccess: 'Obrigado por sua avaliação!',
    },
  },
};
