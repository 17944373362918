import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline, Image } from '~/components/atoms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Align } from '~/components/atoms/headline/Headline';
import { QuizAnswerProps, QuizQuestionProps } from './types';
import ImageSource from '~/components/templates/common/ImageSource';
import { Colors } from '~/utils/theme/colors';

const rootClass: string = 'czt-quiz__question';

@Component
export default class QuizQuestion extends VueComponent<QuizQuestionProps>
  implements QuizQuestionProps {
  @Prop({ required: true })
  public answers!: QuizAnswerProps[];

  @Prop({ required: true })
  public correctAnswerId!: number | string;

  @Prop({ required: true })
  public question!: string;

  @Prop()
  public imageUrl?: string;

  @Prop()
  public imageSource?: string;

  protected selectedAnswer: string | null = null;

  public render() {
    return (
      <v-row class={rootClass}>
        <v-col cols={12} class={`${rootClass}__headline`}>
          <Headline level={4} align={Align.LEFT}>
            {this.question}
          </Headline>
        </v-col>
        <v-col cols={12} md xl={8}>
          <v-item-group
            v-model={this.selectedAnswer}
            onChange={this.handleChange}
          >
            {this.answers.map((answer) => {
              return (
                <v-item
                  key={answer.id}
                  value={answer.id}
                  {...{
                    scopedSlots: {
                      default: ({
                        active,
                        toggle,
                      }: {
                        active: boolean;
                        toggle: () => void;
                      }) => {
                        let backgroundColor;
                        if (
                          this.correctAnswerId === answer.id &&
                          !!this.selectedAnswer
                        ) {
                          backgroundColor = `#72bd43${
                            this.selectedAnswer === answer.id ? '' : 'B0'
                          }`;
                        }
                        if (
                          this.correctAnswerId !== answer.id &&
                          this.selectedAnswer === answer.id
                        ) {
                          backgroundColor = `${Colors.ACCENT}`;
                        }
                        return (
                          <v-card
                            ripple={!this.selectedAnswer}
                            outlined
                            style={{
                              backgroundColor,
                            }}
                            {...(!this.selectedAnswer && {
                              on: {
                                click: toggle,
                              },
                            })}
                            class={{
                              'pa-3': true,
                              'mb-1': true,
                              [`${rootClass}__answer`]: true,
                              [`${rootClass}__answer--relevant`]: !!backgroundColor,
                            }}
                          >
                            <HtmlRenderer
                              class={`${rootClass}__answer__title mb-1`}
                              content={answer.answer}
                            />
                            <v-expand-transition>
                              {this.selectedAnswer && answer.commentary && (
                                <HtmlRenderer
                                  class={`${rootClass}__answer__subtitle`}
                                  content={answer.commentary}
                                  light={!!backgroundColor}
                                />
                              )}
                            </v-expand-transition>
                          </v-card>
                        );
                      },
                    },
                  }}
                />
              );
            })}
          </v-item-group>
        </v-col>
        {this.renderSideContent()}
      </v-row>
    );
  }

  protected renderSideContent() {
    if (!this.imageUrl) {
      return;
    }
    const sizes = [
      `(min-width: 1264px) ${(1150 / 12) * 4}px`,
      `(min-width: 1008px) ${(1000 / 12) * 5}px`,
      `(min-width: 768px) ${(710 / 12) * 6}px`,
      `100vw`,
    ];

    // If side content column width should ever be changed, sizes need to be adjusted too
    return (
      <v-col class={`${rootClass}__side`} cols={12} md={4}>
        <div class={`${rootClass}__side__image`}>
          <Image
            src={this.imageUrl}
            alt={this.question}
            sizes={sizes.join(', ')}
          />
          {!!this.imageSource?.trim() && (
            <ImageSource imageSource={this.imageSource} />
          )}
        </div>
      </v-col>
    );
  }

  @Emit('answer')
  protected handleChange(value: string) {
    this.selectedAnswer = value;
    return value === this.correctAnswerId;
  }
}
