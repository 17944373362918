export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Volver al menú',
      blog: 'Blog',
      exploreButton: 'Explorar',
      language: 'Idioma',
      loadMore: 'Ver más',
      moreInfo: 'Más info',
      opensInNewWindow: 'Se abre en una nueva ventana',
      pageTypes: {
        article: 'Artículo',
        category: 'Categoría',
        event: 'Evento',
        page: 'Página',
        place: 'Lugar',
        region: 'Región',
      },
      selectLanguage: 'Seleccione el idioma',
      homepageBreadcrumb: 'Home',
      translationVoting: '¿Qué opinas de esta traducción?',
      translationVotingSuccess: 'Gracias por tus comentarios.',
    },
  },
};
