import { Component, Prop } from 'vue-property-decorator';
import { IconName } from '~/components/atoms/icons/Icon';
import { VueComponent } from '~/utils/vue-component';

import style from './MapCategoryPin.scss';
import { PinCategory } from './enums';

interface MapCategoryPinProps {
  categoryId: string;
}

const rootClass = 'czt-map-category-pin';

export function getCategoryIcon(categoryId: string): IconName | null {
  switch (categoryId) {
    case PinCategory.ACCOMMODATION:
      return ['map', 'Accomodation'];
    case PinCategory.CULTURE:
      return ['map', 'Culture'];
    case PinCategory.EVENT:
      return ['map', 'Event'];
    case PinCategory.EXPERIENCES:
      return ['map', 'Experience'];
    case PinCategory.LANDMARKS:
      return ['map', 'Landmark'];
    case PinCategory.LIFESTYLE:
      return ['map', 'Lifestyle'];
    case PinCategory.NATURE:
      return ['map', 'Nature'];
    case PinCategory.RESTAURANTS:
      return ['map', 'Restaurant'];
    case PinCategory.SPA:
      return ['map', 'Spa'];
    case PinCategory.SUMMER_SPORTS:
      return ['map', 'SummerSports'];
    case PinCategory.WINTER_SPORTS:
      return ['map', 'WinterSports'];
  }
  return null;
}

export function getCategoryColor(categoryId: string): string {
  switch (categoryId) {
    case PinCategory.EVENT:
      return '#e6001e';
    case PinCategory.CULTURE:
    case PinCategory.RESTAURANTS:
    case PinCategory.LANDMARKS:
      return '#003c78';
    case PinCategory.EXPERIENCES:
    case PinCategory.LIFESTYLE:
    case PinCategory.SPA:
      return '#178fcf';
    case PinCategory.NATURE:
    case PinCategory.SUMMER_SPORTS:
    case PinCategory.WINTER_SPORTS:
      return '#006837';
    case PinCategory.ACCOMMODATION:
    default:
      return '#bdc4c2';
  }
}

@Component({ style })
export default class MapCategoryPin extends VueComponent<MapCategoryPinProps>
  implements MapCategoryPinProps {
  @Prop({ required: true })
  public categoryId!: string;

  protected get categoryIcon(): IconName | null {
    return getCategoryIcon(this.categoryId);
  }

  protected get categoryIconColor() {
    return getCategoryColor(this.categoryId);
  }

  public render() {
    return (
      <div class={rootClass}>
        <v-icon
          class={`${rootClass}__pin`}
          nativeOnClick={() => this.$emit('click')}
          style={{ color: this.categoryIconColor }}
        >
          {'$vuetify.icons.map-Pin'}
        </v-icon>
        {this.categoryIcon && (
          <v-icon
            nativeOnClick={() => this.$emit('click')}
            class={`${rootClass}__category-icon`}
          >
            {`$vuetify.icons.${this.categoryIcon.join('-')}`}
          </v-icon>
        )}
      </div>
    );
  }
}
