import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { Link } from '~/components/molecules';
import { TranslateResult } from 'vue-i18n';

export interface BreadcrumbInterface {
  title: string | TranslateResult;
  url?: string;
  forcedLocale?: string;
  light?: boolean;
}

@Component
export default class BreadcrumbItem extends VueComponent<BreadcrumbInterface>
  implements BreadcrumbInterface {
  @Prop({ required: true })
  public title!: string | TranslateResult;

  @Prop({ type: String })
  public url?: string;

  @Prop({ type: String })
  public forcedLocale?: string;

  @Prop({ default: false })
  public light!: boolean;

  public render() {
    if (!this.url) {
      return <span>{this.title}</span>;
    }
    return (
      <Link url={this.url} forcedLocale={this.forcedLocale} light={this.light}>
        {this.title}
      </Link>
    );
  }
}
