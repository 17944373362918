import {
  DateRange,
  SearchItemInterface,
} from '~/components/molecules/searchItem/SearchItem';
import { CztPage } from '~/utils/views/index';
import {
  SearchItemArticlePage,
  SearchItemEventPage,
  SearchItemPage,
} from '~/app/core/apiClient/api';
import { format, parse } from '~/utils/date-fns';
import { RFC3339Format } from '~/utils/dateTime';

function isEventItem(data: any): data is SearchItemEventPage {
  return data && data.className === CztPage.EVENT;
}

function isArticle(data: any): data is SearchItemArticlePage {
  return data && data.className === CztPage.ARTICLE;
}

// TODO: Rename badData to data
export function createSearchResult(data: SearchItemPage): SearchItemInterface {
  let location: string | undefined;
  let dates: DateRange | undefined;

  if (isArticle(data)) {
    dates = {
      start: data.date ? format(parse(data.date), RFC3339Format) : '',
    };
  } else if (isEventItem(data)) {
    // TODO: Data is missing on the API, uncomment after it is fixed
    // if (data.region) {
    //   location = data.region.regionName;
    // }
    location = '';
    dates = {
      start: data.startDateTime
        ? format(parse(data.startDateTime), RFC3339Format)
        : '',
      end: data.endDateTime
        ? format(parse(data.endDateTime), RFC3339Format)
        : '',
    };
  }

  let type = 'page';

  switch (data.className) {
    case CztPage.ARTICLE:
      type = 'article';
      break;
    case CztPage.CATEGORY:
      type = 'category';
      break;
    case CztPage.EVENT:
      type = 'event';
      break;
    case CztPage.REGION:
      type = 'region';
      break;
    case CztPage.PLACE:
      type = 'place';
      break;
  }

  // Prefer nice url if available
  let path: string = data.permanentPath || '';
  if (
    data.alternativeUrls &&
    data.alternativeUrls.length &&
    data.alternativeUrls[0].url
  ) {
    path = data.alternativeUrls[0].url;
  }

  return {
    dates,
    guid: data.nodeGuid,
    image: {
      src: data.image || '',
      alt: data.title || '',
    },
    location,
    path,
    subtitle: data.title,
    text: data.perex,
    type,
  };
}
