import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ItemData } from '~/components/atoms/multiselect/Multiselect';
import apiUrl from '../../apiClient/apiUrl';

import AbstractModule from './AbstractModule';
import { MapPin } from '~/components/organisms/map/types';
import { PinCategory } from '~/components/templates/mapWidget/enums';
import { isPinCategory } from '~/components/templates/mapWidget/typeguards';

export interface MapPinsRequest {
  latitude: number;
  longitude: number;
  categories: string;
  range: number;
  locale: string;
}

@Module({
  name: 'MapPinsModule',
  stateFactory: true,
  namespaced: true,
})
export default class MapPinsModule extends AbstractModule {
  public categoriesLoading = false;
  public categoryOptions: ItemData[] = [];
  protected currentLocale: string | null = null;

  @Action({ rawError: true })
  public getMapPins({
    latitude,
    longitude,
    categories,
    range,
    locale,
  }: MapPinsRequest): Promise<MapPin[]> {
    return this.$api
      .mapPins()
      .mapPinsGetMapPins(
        latitude,
        longitude,
        range,
        undefined,
        categories || undefined,
        undefined,
        undefined,
        locale
      )
      .then((response) => {
        return response.map((item) => ({
          category:
            item.pageTypeName === 'event'
              ? PinCategory.EVENT
              : item.categories &&
                item.categories.length > 0 &&
                isPinCategory(item.categories[0].categoryGuid)
              ? item.categories[0].categoryGuid
              : PinCategory.DEFAULT,
          id: item.nodeGuid || '',
          image: item.image ? item.image.replace('~', apiUrl) : '',
          latitude: item.latitude || 0,
          longitude: item.longitude || 0,
          title: item.title || '',
          url: item.url || '',
        }));
      });
  }

  @Action({ rawError: true })
  public getMapCategories(locale: string) {
    if (locale === this.currentLocale) {
      Promise.resolve();
    }
    this.setCategoriesLoading(true);
    return this.$api
      .categories()
      .categoriesGetCategories(locale)
      .then((data) => {
        this.setCategoryOptions(
          data
            .map((category) => ({
              text: category.categoryName || '',
              value: category.categoryGuid || '',
            }))
            .filter((category) => {
              return !!category.text && !!category.value;
            })
        );
        this.setCurrentLocale(locale);
      })
      .finally(() => {
        this.setCategoriesLoading(false);
      });
  }

  @Mutation
  protected setCategoriesLoading(state: boolean) {
    this.categoriesLoading = state;
  }

  @Mutation
  protected setCurrentLocale(locale: string) {
    this.currentLocale = locale;
  }

  @Mutation
  protected setCategoryOptions(options: ItemData[]) {
    this.categoryOptions = options;
  }
}
