import addDays from 'date-fns/add_days';
import startOfHour from 'date-fns/start_of_hour';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { parse } from '~/utils/date-fns';
import { formatToCET } from '~/utils/date-fns/format';
import { weatherFormat } from '~/utils/dateTime';
import { VueComponent } from '~/utils/vue-component';

interface WeatherForecastProps {
  latitude: number;
  longitude: number;
}

interface Forecast {
  temperature: number;
  weather: string;
}

const rootClass = 'czt-weather-forecast';

@Component
export default class WeatherForecast extends VueComponent<WeatherForecastProps>
  implements WeatherForecastProps {
  @Prop({ required: true })
  public latitude!: number;

  @Prop({ required: true })
  public longitude!: number;

  protected forecast: { [key: string]: Forecast | null } = {};

  public mounted() {
    const today = new Date();
    const allDays = [...Array(3)].map((_, index) =>
      addDays(startOfHour(today), index)
    );

    this.$api
      .weather()
      .yrNoWeatherWrapperGet(`compact`, undefined, 120, undefined, {
        query: {
          lat: this.latitude,
          lon: this.longitude,
        },
      })
      .then((response) => {
        if (
          response &&
          response.properties &&
          response.properties.timeseries &&
          Array.isArray(response.properties.timeseries) &&
          response.properties.timeseries.length > 0
        ) {
          allDays.forEach((date, index) => {
            const iso = date.toISOString().replace('.000', '');

            const datesData: any[] = response.properties.timeseries.filter(
              (serie: any) => serie.time.indexOf(iso.split('T')[0]) > -1
            );

            let forecastData: any[] = datesData.filter((data) => {
              const dateTimeArray = data.time.split('T');
              if (dateTimeArray.length > 1) {
                const timeArray = dateTimeArray[1].split(':');
                if (timeArray.length > 0) {
                  return timeArray[0] >= 5 && timeArray[0] <= 22;
                }
                return false;
              }
              return false;
            });

            if (forecastData.length < 1) {
              const foundData = datesData.find((data) => data.time === iso);
              forecastData = foundData ? [foundData] : [];
            }

            if (forecastData.length < 1) {
              return;
            }

            let weather: string = '';
            let weatherData = forecastData.find(
              (data) => data && data.data.hasOwnProperty('next_12_hours')
            );

            if (weatherData) {
              weather = weatherData.data.next_12_hours.summary.symbol_code;
            } else {
              weatherData = forecastData.find(
                (data) => data && data.data.hasOwnProperty('next_6_hours')
              );
              if (weatherData) {
                weather = weatherData.data.next_6_hours.summary.symbol_code;
              } else {
                weatherData = forecastData.find(
                  (data) => data && data.data.hasOwnProperty('next_1_hours')
                );
                if (weatherData) {
                  weather = weatherData.data.next_1_hours.summary.symbol_code;
                }
              }
            }

            const forecast: Forecast = {
              temperature:
                forecastData.length > 0
                  ? Math.round(
                      forecastData
                        .map(
                          (data: any) =>
                            data.data.instant.details.air_temperature
                        )
                        .reduce((a, b) => a + b) / forecastData.length
                    )
                  : 0,
              weather,
            };
            Vue.set(this.forecast, date.toISOString(), forecast);
          });
        }
      });
  }

  public render() {
    if (this.forecast && Object.keys(this.forecast).length < 1) {
      return;
    }
    return (
      <div class={rootClass}>
        <Headline align={Align.LEFT} underscore level={4} class='mb-5'>
          {this.$t('app.weather.title')}
        </Headline>
        {Object.keys(this.forecast).map((date, index) => {
          const forecast = this.forecast[date];
          if (!forecast) {
            return;
          }
          const title =
            index === 0
              ? this.$t('app.weather.now')
              : formatToCET(parse(date), weatherFormat, this.$i18n.locale);
          return (
            <v-row no-gutters>
              <v-col cols={12}>
                <Headline
                  align={Align.LEFT}
                  level={5}
                  style={{ textTransform: 'capitalize' }}
                >
                  {title}
                </Headline>
              </v-col>
              <v-col cols={12}>
                <v-row class='flex-nowrap'>
                  {forecast.weather && (
                    <v-col cols='auto'>
                      <v-icon
                        size={`1.75em`}
                      >{`$vuetify.icons.${forecast.weather}`}</v-icon>
                    </v-col>
                  )}
                  <v-col>
                    <v-row no-gutters>
                      <v-col
                        cols={12}
                        class='font-weight-bold'
                      >{`${forecast.temperature} °C`}</v-col>
                      {forecast.weather && (
                        <v-col cols={12} class='czt-description'>
                          {this.$t(
                            `app.weather.weatherTranslations.${forecast.weather}`
                          )}
                        </v-col>
                      )}
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          );
        })}
        <br />
      </div>
    );
  }
}
