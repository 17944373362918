import { Action, Module, Mutation } from 'vuex-module-decorators';
import boolToNumber from '~/utils/boolToNumber';
import {
  COOKIE_NAME,
  COOKIE_DELIMITER,
  CookieCategory,
  COOKIES_ACCEPTED_EXPIRATION_TIME,
  COOKIES_REJECTED_EXPIRATION_TIME,
  VERSION_COOKIE_NAME,
  VERSION,
} from '~/utils/cookies/config';
import cookieCategoryValue from '~/utils/cookies/cookieCategoryValue';
import cztCookieExists from '~/utils/cookies/cookieExists';
import getCookieValue from '~/utils/cookies/getCookieValue';
import gtm from '~/utils/gtm';
import appEnv from '../../appEnv';

import AbstractModule from './AbstractModule';

export interface CookieSettings {
  analytics: boolean;
  statistics: boolean;
  marketing: boolean;
}

function setVersionCookie(): void {
  const date = new Date();
  date.setTime(date.getTime() + COOKIES_ACCEPTED_EXPIRATION_TIME);
  document.cookie = `${VERSION_COOKIE_NAME}=${VERSION}; expires=${date.toUTCString()}`;
}

@Module({
  name: 'CookieSettingsModule',
  stateFactory: true,
  namespaced: true,
})
export default class CookieSettingsModule extends AbstractModule {
  public gtmLoaded: boolean = false;

  public cookieBarVisible: boolean = false;
  public cookieExists: boolean = false;

  public analytics: boolean = false;
  public statistics: boolean = false;
  public marketing: boolean = false;

  protected cookiePromise: Promise<boolean> | null = null;

  @Action({ rawError: true })
  public readCookie(cookiesString: string): Promise<boolean> {
    if (this.cookiePromise) {
      return this.cookiePromise;
    }
    const promise = new Promise<boolean>((resolve, reject) => {
      if (
        getCookieValue(VERSION_COOKIE_NAME, cookiesString) !==
        VERSION.toString()
      ) {
        this.setCookieExists(false);
        this.setCookieBarVisible(true);
        resolve(false);
        return;
      }
      if (cztCookieExists(COOKIE_NAME, cookiesString)) {
        this.setCookieExists(true);
        this.setCookieBarVisible(false);
        const value = getCookieValue(COOKIE_NAME, cookiesString);
        if (value) {
          const categoryValues = value.split(COOKIE_DELIMITER);
          categoryValues.forEach((item) => {
            const category = item[0];
            switch (category) {
              case CookieCategory.ANALYTICS:
                this.setAnalytics(
                  cookieCategoryValue(item, CookieCategory.ANALYTICS)
                );
                break;
              case CookieCategory.STATISTICS:
                this.setStatistics(
                  cookieCategoryValue(item, CookieCategory.STATISTICS)
                );
                break;
              case CookieCategory.MARKETING:
                this.setMarketing(
                  cookieCategoryValue(item, CookieCategory.MARKETING)
                );
                break;
            }
          });
        }
        resolve(true);
      } else {
        this.setCookieExists(false);
        this.setCookieBarVisible(true);
        resolve(false);
      }
    });

    this.setPromise(promise);

    return promise;
  }

  @Action({ rawError: true })
  public loadGTM(): Promise<void> {
    if (!this.gtmLoaded) {
      gtm(appEnv.GTM_CONTAINER);
      this.setGTMLoaded(true);
    }
    return Promise.resolve();
  }

  @Action({ rawError: true })
  public saveCookie(cookieSettings: CookieSettings): Promise<void> {
    if (
      cookieSettings.analytics === this.analytics &&
      cookieSettings.marketing === this.marketing &&
      cookieSettings.statistics === this.statistics &&
      this.cookieExists
    ) {
      this.setCookieBarVisible(false);
      return Promise.resolve();
    }
    const date = new Date();
    const expiry: number =
      cookieSettings.marketing && cookieSettings.statistics
        ? COOKIES_ACCEPTED_EXPIRATION_TIME
        : COOKIES_REJECTED_EXPIRATION_TIME;
    date.setTime(date.getTime() + expiry);
    const cookie = [
      `${CookieCategory.REQUIRED}1`,
      `${CookieCategory.ANALYTICS}${boolToNumber(cookieSettings.analytics)}`,
      `${CookieCategory.STATISTICS}${boolToNumber(cookieSettings.statistics)}`,
      `${CookieCategory.MARKETING}${boolToNumber(cookieSettings.marketing)}`,
    ].join(COOKIE_DELIMITER);
    document.cookie = `${COOKIE_NAME}=${cookie}; expires=${date.toUTCString()}`;
    setVersionCookie();
    this.setCookieExists(true);
    return this.setCookieSettings(cookieSettings).then(() => {
      this.setCookieBarVisible(false);
      return Promise.resolve();
    });
  }

  @Action({ rawError: true })
  protected setCookieSettings(cookieSettings: CookieSettings): Promise<void> {
    this.setAnalytics(cookieSettings.analytics);
    this.setStatistics(cookieSettings.statistics);
    this.setMarketing(cookieSettings.marketing);
    if (typeof window !== 'undefined') {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }
      window.dataLayer.push({ event: 'consent_update' });
    }
    return Promise.resolve();
  }

  @Mutation
  public setCookieBarVisible(state: boolean) {
    this.cookieBarVisible = state;
  }

  @Mutation
  public setCookieExists(state: boolean) {
    this.cookieExists = state;
  }

  @Mutation
  protected setAnalytics(state: boolean) {
    this.analytics = state;
  }

  @Mutation
  protected setStatistics(state: boolean) {
    this.statistics = state;
  }

  @Mutation
  protected setMarketing(state: boolean) {
    this.marketing = state;
  }

  @Mutation
  protected setPromise(promise: Promise<boolean>) {
    this.cookiePromise = promise;
  }

  @Mutation
  protected setGTMLoaded(state: boolean) {
    this.gtmLoaded = state;
  }
}
