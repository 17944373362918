import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import VueSlickCarousel from 'vue-slick-carousel';

import CarouselItem, {
  CarouselItemInterface,
} from '~/components/molecules/carouselItem/CarouselItem';
import { Headline, Icons } from '~/components/atoms';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import style from './Carousel.scss';
import { CztWidgets } from '~/utils/views/widgets';
import { CarouselInterface, CarouselOptions } from './types';
import { Align } from '~/components/atoms/headline/Headline';

export const carouselLazyDelay = 250;
const carouselSpeed = 500;
const carouselClickDelay = 50;

const rootClass = 'czt-carousel';

@Component({
  style,
})
export default class Carousel extends VueComponent<CarouselInterface>
  implements CarouselInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public anchorName?: string;

  @Prop({ required: true })
  public items!: CarouselItemInterface[];

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop({ type: Boolean, default: false })
  public contained!: boolean;

  @Prop({
    default: () => {
      // Need to split it to multiple lines for tslint
      let carouselOptions: CarouselOptions;
      carouselOptions = {
        focusOnSelect: true,
        infinite: false,
        variableWidth: true,
        swipeToSlide: true,
      };
      return carouselOptions;
    },
  })
  public carouselOptions!: CarouselOptions;

  public className = CztWidgets.CAROUSEL;

  @Ref('carousel')
  protected readonly carousel?: typeof VueSlickCarousel;

  /**
   * Flag indicating that the carousel is in the process of sliding
   * When sliding, the currentSlide should not change because the carousel
   * implementation does not allow changing the target slide during animation
   * and if this is overloaded using props, it flickers, so it sucks anyway!
   */
  protected sliding: boolean = false;

  /**
   * Slide that should be shown as the currently viewed item in the carousel
   */
  protected currentSlide: number = 0;

  protected get prevArrowClass(): string {
    const classes = [
      `${rootClass}__navigation`,
      `${rootClass}__navigation--prev`,
    ];

    if (this.currentSlide === 0) {
      classes.push(`${rootClass}__navigation--disabled`);
    }

    return classes.join(' ');
  }
  protected get nextArrowClass(): string {
    const classes = [
      `${rootClass}__navigation`,
      `${rootClass}__navigation--next`,
    ];
    let stopAtSlideNr = this.items.length - 1;

    if (!this.contained) {
      if (this.$vuetify.breakpoint.mdAndUp && this.items.length > 1) {
        stopAtSlideNr = this.items.length - 2;
      }
      if (this.$vuetify.breakpoint.width >= 1008 && this.items.length > 2) {
        stopAtSlideNr = this.items.length - 3;
      }
    }
    if (this.currentSlide >= stopAtSlideNr) {
      classes.push(`${rootClass}__navigation--disabled`);
    }

    return classes.join(' ');
  }

  public render() {
    if (this.items.length < 1) {
      return;
    }

    const rootClasses: string[] = [
      rootClass,
      this.contained ? 'mb-3' : 'czt-spacer',
    ];
    if (this.contained) {
      rootClasses.push(`${rootClass}--contained`);
    }
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }

    const headline = (
      <Headline
        class={!this.contained ? `${rootClass}__headline` : undefined}
        level={this.contained ? 3 : 2}
        underscore
        align={this.contained ? Align.LEFT : undefined}
      >
        {this.title}
      </Headline>
    );

    return (
      <v-sheet id={this.anchorId} class={rootClasses.join(' ')}>
        {this.contained ? headline : <v-container>{headline}</v-container>}
        <div class={`${rootClass}__wrapper`}>
          <div class={this.prevArrowClass} onClick={this.moveBackward}>
            <div class={`${rootClass}__navigation__background`} />
            <Icons.common.ArrowLeft />
          </div>
          <div class={this.nextArrowClass} onClick={this.moveForward}>
            <div class={`${rootClass}__navigation__background`} />
            <Icons.common.ArrowRight />
          </div>
          <VueSlickCarousel
            ref='carousel'
            onBeforeChange={() => {
              this.sliding = true;
            }}
            onAfterChange={(slideIndex: number) => {
              setTimeout(() => {
                this.sliding = false;
              }, carouselClickDelay);
              this.currentSlide = slideIndex;
            }}
            {...{
              props: {
                ...this.carouselOptions,
                // Hardcode the following props,
                // arrows are reimplemented due to bugs in vue carousel
                arrows: false,
              },
            }}
          >
            {this.getSlides()}
          </VueSlickCarousel>
        </div>
      </v-sheet>
    );
  }

  protected getSlides() {
    if (!this.items || (Array.isArray(this.items) && this.items.length < 1)) {
      return [...Array(3).keys()].map((index) => {
        return <v-skeleton-loader type='image' key={index} />;
      });
    }
    return this.items.map((item: CarouselItemInterface, index) => {
      return (
        <CarouselItem
          forcedLocale={item.forcedLocale}
          isFirst={this.isFirst && index === 0}
          image={item.image}
          imageFilter={item.imageFilter}
          title={item.title}
          date={item.date}
          endDate={item.endDate}
          location={item.location}
          key={index + item.title}
          url={item.url}
          disabled={this.sliding}
        />
      );
    });
  }

  protected moveForward() {
    if (!this.carousel || this.sliding) {
      return;
    }
    this.sliding = true;
    const moveNr = this.$vuetify.breakpoint.lgAndUp ? 2 : 1;

    this.currentSlide =
      this.currentSlide + moveNr <= this.items.length - 1
        ? this.currentSlide + moveNr
        : this.items.length - 1;

    this.carousel.goTo(this.currentSlide);

    // Ugly hack to fix slider bug. The slider has no longer any updates and should be replaced!!!
    setTimeout(() => {
      this.sliding = false;
    }, carouselSpeed + carouselClickDelay);
  }

  protected moveBackward() {
    if (!this.carousel || this.sliding) {
      return;
    }
    this.sliding = true;
    const moveNr = this.$vuetify.breakpoint.width >= 1008 ? 2 : 1;

    this.currentSlide =
      this.currentSlide - moveNr >= 0 ? this.currentSlide - moveNr : 0;

    this.carousel.goTo(this.currentSlide);

    // Ugly hack to fix slider bug. The slider has no longer any updates and should be replaced!!!
    setTimeout(() => {
      this.sliding = false;
    }, carouselSpeed + carouselClickDelay);
  }
}
