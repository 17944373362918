export default {
  app: {
    common: {
      exploreButton: 'Atraskite',
      introPageNavTitle: 'Intro',
      homepageBreadcrumb: 'Pradžia',
      language: 'Kalba',
      loadMore: 'Plačiau',
      moreInfo: 'Daugiau informacijos',
      opensInNewWindow: 'Atsidaro naujame lange',
      pageTypes: {
        article: 'Straipsnis',
        category: 'Kategorija',
        event: 'Įvykis',
        page: 'Puslapis',
        place: 'Vieta',
        region: 'Regionas',
      },
      selectLanguage: 'Pasirinkite kalbą',
      source: 'Šaltinis',
      translationVoting: 'Kaip Jums patinka šis vertimas?',
      translationVotingSuccess: 'Ačiū už Jūsų atsiliepimą!',
    },
  },
};
