export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Vissza a menübe',
      blog: 'Blog',
      exploreButton: 'Felfedezés',
      homepageBreadcrumb: 'Itthon',
      language: 'Nyelv',
      loadMore: 'Többet látni',
      moreInfo: 'További információ',
      opensInNewWindow: 'Új ablakban nyílik meg',
      pageTypes: {
        article: 'Cikk',
        category: 'Kategóriák',
        event: 'Esemény',
        page: 'Oldal',
        place: 'Hely',
        region: 'Vidék',
      },
      selectLanguage: 'Válasszon nyelvet',
      translationVoting: 'Hogy tetszik ez a fordítás?',
      translationVotingSuccess: 'Köszönjük visszajelzését!',
    },
  },
};
