export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'メニューに戻る',
      blog: 'ブログ',
      exploreButton: '見る',
      language: '言語',
      loadMore: 'もっと',
      moreInfo: 'もっと見る',
      opensInNewWindow: '新しいウィンドウで開きます',
      pageTypes: {
        article: '論文',
        category: 'カテゴリー',
        event: 'イベント',
        page: 'ページ',
        place: '場所',
        region: '領域',
      },
      selectLanguage: '言語を選択する',
      homepageBreadcrumb: 'ホーム',
      translationVoting: 'この翻訳の質はいかがですか？',
      translationVotingSuccess: 'ご意見ありがとうございました。',
    },
  },
};
