export default {
  app: {
    common: {
      introPageNavTitle: 'Intro',
      backToMenu: 'Tilbake til menyen',
      blog: 'Blog',
      exploreButton: 'Oppdag',
      homepageBreadcrumb: 'Hjemmeside',
      language: 'Språk',
      loadMore: 'Se mer',
      moreInfo: 'Mer informasjon',
      opensInNewWindow: 'Åpnes i et nytt vindu',
      pageTypes: {
        article: 'Article',
        category: 'Category',
        event: 'Event',
        page: 'Page',
        place: 'Place',
        region: 'Region',
      },
      selectLanguage: 'Velg språk',
      translationVoting: 'Hvordan liker du denne oversettelsen?',
      translationVotingSuccess: 'Takk for din tilbakemelding!',
    },
  },
};
