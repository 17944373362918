import {
  mdiAirplaneTakeoff,
  mdiImageFilterHdr,
  mdiMap,
  mdiSkiCrossCountry,
  mdiSnowflake,
} from '~/utils/iconPaths';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { ThemeColors } from '~/utils/theme';
import { VueComponent } from '~/utils/vue-component';
import { MapStyle } from '~/components/organisms/map/enums';

interface MapStyleSwitchProps {
  currentStyle: MapStyle;
  onStyleChange?: (style: MapStyle) => void;
}

@Component
export default class MapStyleSwitch extends VueComponent<MapStyleSwitchProps>
  implements MapStyleSwitchProps {
  @Prop({ required: true })
  public currentStyle!: MapStyle;

  protected open: boolean = false;

  public render() {
    const month = new Date().getMonth();
    return (
      <v-speed-dial
        absolute
        v-model={this.open}
        top
        left
        direction='bottom'
        style={{
          left: '100px',
        }}
      >
        <v-btn
          v-model={this.open}
          color={ThemeColors.PRIMARY}
          dark
          fab
          slot='activator'
        >
          <v-icon>{mdiMap}</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color={
            this.currentStyle === MapStyle.BASIC
              ? ThemeColors.ACCENT
              : ThemeColors.SECONDARY
          }
          onClick={(e: Event) => {
            e.stopImmediatePropagation();
            this.changeStyle(MapStyle.BASIC);
          }}
        >
          <v-icon>{mdiImageFilterHdr}</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color={
            this.currentStyle === MapStyle.OUTDOOR
              ? ThemeColors.ACCENT
              : ThemeColors.SECONDARY
          }
          onClick={(e: Event) => {
            e.stopImmediatePropagation();
            this.changeStyle(MapStyle.OUTDOOR);
          }}
        >
          <v-icon>{mdiSkiCrossCountry}</v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          small
          color={
            this.currentStyle === MapStyle.AERIAL
              ? ThemeColors.ACCENT
              : ThemeColors.SECONDARY
          }
          onClick={(e: Event) => {
            e.stopImmediatePropagation();
            this.changeStyle(MapStyle.AERIAL);
          }}
        >
          <v-icon>{mdiAirplaneTakeoff}</v-icon>
        </v-btn>
        {(month < 4 || month > 9) && (
          <v-btn
            fab
            dark
            small
            color={
              this.currentStyle === MapStyle.WINTER
                ? ThemeColors.ACCENT
                : ThemeColors.SECONDARY
            }
            onClick={(e: Event) => {
              e.stopImmediatePropagation();
              this.changeStyle(MapStyle.WINTER);
            }}
          >
            <v-icon>{mdiSnowflake}</v-icon>
          </v-btn>
        )}
      </v-speed-dial>
    );
  }

  @Emit('styleChange')
  protected changeStyle(style: MapStyle) {
    return style;
  }
}
