import ServerHeadManagement from './ServerHeadManagement';
import ClientHeadManagement from './ClientHeadManagement';
import favicons from '~/assets/favicons';
import { defaultTitle } from '~/utils/views/createSeoTags';

/**
 * Helper function to retrieve a property from the vue component
 *
 * The vue component properties must always return a promise in order for
 * server side rendering to work
 *
 * Alternatively they can also be specified as a plain string
 *
 * @param propertyName
 * @param vm
 */
function getProperty(propertyName: string, vm: any): string | null {
  // components can simply provide a `title` function which returns a promise
  const { [propertyName]: property } = vm;

  if (typeof property === 'function') {
    return property.call(vm);
  } else if (property) {
    return property;
  }

  return null;
}

/*
 * Define properties which can be used to alter the contents of <head>
 * These are injected at ./server/index.template.html and are initialized
 * in render function of ./server/main.js
 */
export function getTitle(vm: any): string {
  const title = getProperty('title', vm);
  if (title === null) {
    return '';
  }
  const parts: string[] = [title, defaultTitle].filter((item) => item);
  return parts.join(' | ');
}

export function getHeadTags(vm: any): string {
  return getProperty('headTags', vm) || '';
}

export function getInlineCss(vm: any): string {
  const inlineCss = getProperty('inlineCss', vm);
  return inlineCss ? `<style type="text/css">${inlineCss}</style>` : '';
}

export function getFontTags(vm: any): string {
  return getProperty('fontTags', vm) || '';
}

export function getFavicons(): string {
  return favicons;
}

export function replaceDocumentHeadContext(
  contextName: string,
  replacement: string
) {
  const head = document.head;

  const startComment = Array.from(head.childNodes).find(
    (node) =>
      node.nodeType === Node.COMMENT_NODE &&
      node.nodeValue?.trim() === contextName
  );

  const endComment = Array.from(head.childNodes).find(
    (node) =>
      node.nodeType === Node.COMMENT_NODE &&
      node.nodeValue?.trim() === `/${contextName}`
  );

  if (startComment && endComment) {
    const range = document.createRange();
    range.setStartAfter(startComment);
    range.setEndBefore(endComment);

    range.deleteContents();

    const fragment = range.createContextualFragment(replacement);

    range.insertNode(fragment);
  }
}

export interface HeadManagementInterface {
  replaceHead: () => void;
}

export default process.env.VUE_ENV === 'server'
  ? ServerHeadManagement
  : ClientHeadManagement;
