import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { ThemeColors } from '~/utils/theme';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import { Button, Headline, Image } from '~/components/atoms';
import Dialog from '~/components/organisms/dialog/Dialog';
import { supportedLocales } from '~/app/localization';

import logo from '~/assets/logo.svg';
import kznLogo from '~/assets/kzn-logo.svg';

import style from './LanguagePopup.scss';
import { LanguageLocaleInterface } from '~/utils/localization';

const rootClass = 'czt-language-popup';

interface LanguagePopupInterface {
  active: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

@Component({ style })
export default class LanguagePopup extends VueComponent<LanguagePopupInterface>
  implements LanguagePopupInterface {
  @Prop({ required: true })
  public active!: boolean;

  protected get isCrawler(): boolean {
    return getModule(RouterModule, this.$store).isCrawler;
  }

  protected get languageModule(): LanguageModule {
    return getModule(LanguageModule, this.$store);
  }

  protected get isKznPrompt(): boolean {
    return ['cs-CZ', 'sk-SK'].includes(this.languageModule.preferredLanguage);
  }

  protected get preferredLocale(): LanguageLocaleInterface | null {
    return (
      supportedLocales.find(
        (sl) =>
          sl.slug.toLowerCase() ===
          this.languageModule.preferredLanguage.toLowerCase()
      ) || null
    );
  }

  public render() {
    if (this.isCrawler || (!this.preferredLocale && !this.isKznPrompt)) {
      return;
    }

    return (
      <Dialog
        contentClass={rootClass}
        active={this.active}
        onCloseDialog={this.handleClose}
        backgroundColor={ThemeColors.TEXT_LIGHT}
        maxWidth={750}
      >
        {this.preferredLocale && !this.isKznPrompt && (
          <v-row class={`${rootClass}__content justify-center`}>
            <v-col cols='12'>
              <Headline level={3}>
                <i18n
                  path='app.languagePopup.title'
                  locale={this.preferredLocale.slug}
                >
                  <Image
                    slot='logo'
                    src={logo}
                    class={`${rootClass}__logo`}
                    alt='#VisitCzechia Logo'
                    lazy={false}
                  />
                </i18n>
              </Headline>
            </v-col>
            <v-col cols='12'>
              <Headline level={5}>
                {this.$t(
                  'app.languagePopup.subtitle',
                  this.preferredLocale.slug
                )}
              </Headline>
            </v-col>
            <v-col cols='12' class='text-center'>
              <Button
                url='/'
                forcedLocale={this.preferredLocale.slug}
                onClick={this.handleClose}
              >
                {this.$t('app.languagePopup.cta', this.preferredLocale.slug, {
                  lang: this.preferredLocale.name,
                })}
              </Button>
            </v-col>
          </v-row>
        )}
        {this.isKznPrompt && this.specialDialogContent()}
      </Dialog>
    );
  }

  protected specialDialogContent() {
    return (
      <v-row class={`${rootClass}__content justify-center`}>
        <v-col cols='12'>
          <Headline level={3}>
            Právě používáte anglickou verzi{' '}
            <Image
              src={logo}
              class={`${rootClass}__logo`}
              alt='VisitCzechia Logo'
              lazy={false}
            />
          </Headline>
        </v-col>
        <v-col cols='12'>
          <Headline level={5}>
            Bohužel Vámi preferovaný jazyk není podporován, můžeme Vás ale
            přesměrovat na{' '}
            <Image
              src={kznLogo}
              class={`${rootClass}__logo ${rootClass}__logo--kzn`}
              alt='Kudy z Nudy Logo'
              lazy={false}
            />
            , kde najdete více než 80 000 tipů na výlety.
          </Headline>
        </v-col>
        <v-col cols='12' class='text-center'>
          <Button url='https://www.kudyznudy.cz/'>
            Přepnout na Kudy z nudy
          </Button>
        </v-col>
      </v-row>
    );
  }

  protected handleClose() {
    this.languageModule.setLanguageOffered(true);
  }
}
