import cheerio from 'cheerio';
import { Component, Ref, Vue } from 'vue-property-decorator';

import style from './PageNavigation.scss';
import { TranslateResult } from 'vue-i18n';
import { Widget } from '~/utils/views/widgets';

export interface PageAnchor {
  id: string;
  title: string | TranslateResult;
}

export interface PageNavigationComponent {
  contentAnchors: PageAnchor[] | null;
  widgetAnchors: PageAnchor[] | null;
  renderPageNavigation: () => JSX.Element | null;
  setContentAnchors: (content: string) => void;
  setWidgetAnchors: (widgets: Widget[]) => void;
}

interface AnchorBreakpoint {
  id: string;
  breakpoint: number;
}

function isAnchorBreakpoint(data: unknown): data is AnchorBreakpoint {
  return (
    typeof data === 'object' && !!data && 'id' in data && 'breakpoint' in data
  );
}

const rootClass = 'czt-page-navigation';

const contentId = 'anchor_content';

@Component({
  style,
})
export default class PageNavigation extends Vue
  implements PageNavigationComponent {
  @Ref('pageNav')
  public pageNav!: HTMLDivElement;

  public contentAnchors: PageAnchor[] | null = null;

  public widgetAnchors: PageAnchor[] | null = null;

  public get anchors(): PageAnchor[] {
    if (!this.contentAnchors || !this.widgetAnchors) {
      return [];
    }
    return [
      { id: contentId, title: this.$t('app.common.introPageNavTitle') },
      ...this.contentAnchors,
      ...this.widgetAnchors,
    ];
  }

  protected anchorBreakpoints: AnchorBreakpoint[] = [];

  protected fixedNav = false;

  protected goingToAnchor = false;

  protected activeTab: string = contentId;

  protected scrollHeight: number = 0;

  protected get activeTabIndex() {
    return this.anchors.findIndex((anchor) => anchor.id === this.activeTab);
  }

  public mounted() {
    if (this.anchors.length < 2) {
      return;
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    this.handleScroll();
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  public renderPageNavigation() {
    if (this.anchors.length < 2) {
      return null;
    }
    return (
      <div class={rootClass} ref='pageNav' key={this.anchors.length}>
        <v-app-bar
          fixed={this.fixedNav}
          style={{ top: this.fixedNav ? '99px' : undefined }}
        >
          <v-tabs
            active-class={`${rootClass}__tab--active`}
            class={`${rootClass}__tabs px-0 px-xl-3`}
            show-arrows
            value={this.activeTabIndex}
          >
            <v-tabs-slider class={`${rootClass}__slider`} />
            {this.anchors.map((anchor) => (
              <v-tab
                key={anchor.id}
                class={`${rootClass}__tab`}
                onClick={() => this.handleChange(anchor.id)}
              >
                {anchor.title}
              </v-tab>
            ))}
          </v-tabs>
        </v-app-bar>
      </div>
    );
  }

  public setContentAnchors(content: string) {
    const dom = cheerio.load(content);
    const pageAnchors: PageAnchor[] = [];
    dom('a').each((index, element) => {
      const el = dom(element);
      const id = el.attr('id');
      const name = el.attr('name');
      if (id && name) {
        const newId = id
          .toLowerCase()
          .split(' ')
          .join('_');
        pageAnchors.push({
          id: newId,
          title: name,
        });
      }
    });
    this.contentAnchors = pageAnchors;
  }

  public setWidgetAnchors(widgets: Widget[]) {
    const anchors: PageAnchor[] = widgets
      .filter(
        (
          widget
        ): widget is Widget & {
          anchorId: string;
          title: string;
          anchorName: string;
        } =>
          'anchorId' in widget && ('anchorName' in widget || 'title' in widget)
      )
      .map((widget) => ({
        id: widget.anchorId,
        title: widget.anchorName || widget.title?.substring(0, 31),
      }));
    this.widgetAnchors = anchors;
  }

  protected handleChange(index: string) {
    const selectedAnchor = this.anchors.find((a) => a.id === index);
    if (!selectedAnchor) {
      return;
    }
    this.activeTab = index;
    const anchor = document.getElementById(selectedAnchor.id);
    if (anchor && anchor instanceof HTMLElement) {
      this.goingToAnchor = true;
      this.$vuetify
        .goTo(anchor, {
          offset: 64,
        })
        .finally(() => {
          this.goingToAnchor = false;
        });
    }
  }

  protected handleScroll() {
    if (this.scrollHeight !== document.body.scrollHeight) {
      this.handleResize();
    }
    this.fixedNav =
      window.scrollY >=
      window.scrollY + this.pageNav.getBoundingClientRect().top - 100;
    if (this.goingToAnchor) {
      return;
    }
    this.activeTab =
      this.anchorBreakpoints.reduce((best, obj) =>
        obj.breakpoint <= window.scrollY &&
        (!best || obj.breakpoint > best.breakpoint)
          ? obj
          : best
      )?.id || this.activeTab;
  }

  protected handleResize() {
    this.scrollHeight = document.body.scrollHeight;
    this.anchorBreakpoints = this.anchors
      .map((anchor) => {
        const anchorElement = document.getElementById(anchor.id);
        if (!anchorElement) {
          return;
        }
        return {
          id: anchor.id,
          breakpoint:
            window.scrollY + anchorElement.getBoundingClientRect().top - 200,
        };
      })
      .filter(isAnchorBreakpoint);
  }
}
