import { Mixins, Vue } from 'vue-property-decorator';
import { VueClass } from 'vue-class-component/lib/declarations';

export interface ValueProp<T> {
  onInput?: (value: T) => void;
  'v-model'?: T;
  value?: T;
}

type DefaultVueProperties<T> = T & {
  class?: string;
  id?: string;
  key?: string | number;
  on?: object;
  onClick?: (e: Event) => void;
  ref?: string;
  slot?: any;
  style?: any;
  itemprop?: any;
  itemscope?: any;
  itemtype?: any;
};

export class VueComponent<P> extends Vue {
  public readonly $props!: DefaultVueProperties<P>;
}

export function VueComponentMixin<P, A>(
  ...args: any[]
): VueClass<VueComponent<P> & A> {
  return Mixins(...args);
}
