import { MapStyle } from './enums';

export function isMapStyle(data: any): data is MapStyle {
  for (const value in MapStyle) {
    if (MapStyle.hasOwnProperty(value)) {
      if (data === (MapStyle as any)[value]) {
        return true;
      }
    }
  }
  return false;
}
